.head-search[data-v-20b183c4] {
  position: relative;
}
.head-search .el-input[data-v-20b183c4] {
  width: 300px;
  position: absolute;
  left: 20%;
  top: 6px;
}
.tag-content[data-v-20b183c4] {
  padding: 5px 12px;
  background: #fff;
}